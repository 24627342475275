/*
    Row z-indexes layout.

    Left fixed: 5
    Center
    Right fixed: 5

    Cell:
        Overlay
        * borders can overlay cell on top, impossible to overlay bottom cells because position: sticky creates isolation context:
            Hover: 1
            Focus: 2
            Invalid: 3

    DropMarker: 10
*/
.container {
  position: relative;
  display: flex;
  align-items: stretch;
  background: var(--uui-dt-row-bg);
}

.section {
  display: flex;
  border-bottom: var(--uui-dt-row-border-width) solid transparent;
  background: var(--uui-dt-row-bg);
  background-clip: content-box;
  box-sizing: border-box;
}
.section:has(:global(.uui-table-cell-selection-in-progress)) {
  cursor: crosshair;
}

.scrolling-section {
  position: relative;
  align-items: stretch;
}

.fixed-columns-section-left,
.fixed-columns-section-right {
  position: sticky;
  z-index: 5;
}

.fixed-columns-section-left {
  inset-inline-start: 0;
  border-inline-end: var(--uui-dt-cell-border-width) solid transparent;
}

.fixed-columns-section-right {
  inset-inline-end: 0;
}

:global(.uui-scroll-shadow-left) {
  position: absolute;
  top: 0;
  bottom: 0;
  inset-inline-start: -5px;
  width: 5px;
  pointer-events: none;
  background: var(--uui-dt-inset-inline-start-shadow);
  opacity: 0;
}

:global(.uui-scroll-shadow-right) {
  position: absolute;
  top: 0;
  bottom: 0;
  inset-inline-end: -5px;
  width: 5px;
  pointer-events: none;
  background: var(--uui-dt-inset-inline-end-shadow);
  opacity: 0;
}

.groupColumnsWrapper {
  display: flex;
  flex-direction: row;
}

:global(.uui-table-column-group-wrapper) {
  display: flex;
  flex-direction: column;
}