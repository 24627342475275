.row-wrapper {
  --uui-dt-columns_config_modal-row-vertical-padding: 3px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  touch-action: auto;
  column-gap: var(--uui-horizontal-gap);
  padding: var(--uui-dt-columns_config_modal-row-vertical-padding) var(--uui-dt-columns_config_modal-padding);
}
.row-wrapper .pin-icon-button {
  min-height: auto;
  column-gap: var(--uui-dt-columns_config_modal-pin_icon_button-column-gap);
  padding-top: var(--uui-dt-columns_config_modal-row-vertical-padding);
  padding-bottom: var(--uui-dt-columns_config_modal-row-vertical-padding);
}
.row-wrapper.not-pinned:not(:hover):not(:focus-within) .pin-icon-button {
  visibility: hidden;
}
.row-wrapper .checkbox {
  flex-grow: 1;
  padding-top: var(--uui-dt-columns_config_modal-row-vertical-padding);
  padding-bottom: var(--uui-dt-columns_config_modal-row-vertical-padding);
}
.row-wrapper:hover {
  background-color: var(--uui-surface-highest);
}
.row-wrapper .drag-handle {
  opacity: 1;
  touch-action: none;
  padding-top: var(--uui-dt-columns_config_modal-row-vertical-padding);
  padding-bottom: var(--uui-dt-columns_config_modal-row-vertical-padding);
}
.row-wrapper .drag-handle.dnd-disabled {
  opacity: 0.3;
  touch-action: auto;
}
.row-wrapper .drag-handle :global(.uui-icon) {
  fill: var(--uui-control-icon);
}
.row-wrapper:global(.uui-drag-ghost) {
  box-shadow: var(--uui-shadow-level-3);
  background-color: var(--uui-surface-main);
}