.root {
  --uui-data-picker-size: var(--uui-size);
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
  display: flex;
  align-items: flex-start;
  padding: 0 var(--uui-data_picker_cell-horizontal-padding);
}
.root:global(.data-picker-cell).align-widgets-top div:global(.uui-checkbox) {
  margin-top: calc(var(--uui-data-picker-size) / 2 - var(--uui-size) / 2);
}
.root.align-widgets-center :global(.uui-checkbox-container) {
  align-self: center;
}
.root:first-child {
  border-left-color: transparent;
}
.root:last-child {
  border-right-color: transparent;
}

.icon-container {
  fill: var(--uui-icon);
}

.loading-cell div[aria-busy=true] > span {
  margin: 0 calc(var(--uui-grid-unit) / 2) 0 0;
}

.content-wrapper {
  display: flex;
  min-width: 0;
  flex: 1;
}

.render-item {
  display: flex;
  flex: auto;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-default {
  fill: var(--uui-icon);
}

.selected-mark {
  fill: var(--uui-picker_input-selected_mark);
}