.root {
  --uui-dt-columns_config_modal-padding: 24px;
  --uui-dt-columns_config_modal-horizontal-gap: 6px;
  --uui-dt-columns_config_modal-search_area-min-height: 42px;
  --uui-dt-columns_config_modal-pin_icon_button-column-gap: 6px;
  --uui-dt-columns_config_modal-group_title-font-size: 14px;
  --uui-dt-columns_config_modal-group_title-line-height: 18px;
  --uui-dt-columns_config_modal-group_title-font-weight: 600;
  --uui-dt-columns_config_modal-subgroup-min-height: 24px;
  --uui-dt-columns_config_modal-subgroup_title-font-size: 10px;
  --uui-dt-columns_config_modal-subgroup_title-line-height: 12px;
  --uui-dt-columns_config_modal-subgroup_title-font-weight: 600;
  --uui-dt-columns_config_modal-no_data_title-line-height: 30px;
  --uui-dt-columns_config_modal-no_data_title-font-size: 24px;
  --uui-dt-columns_config_modal-no_data_title-font-weight: 600;
  --uui-dt-columns_config_modal-no_data_sub_title-line-height: 24px;
  --uui-dt-columns_config_modal-no_data_sub_title-font-size: 16px;
  --uui-dt-columns_config_modal-no_data_sub_title-font-weight: 400;
}
.root .main-panel {
  height: 100%;
}
.root .main-panel .group {
  padding: 0 var(--uui-dt-columns_config_modal-padding);
  column-gap: var(--uui-dt-columns_config_modal-horizontal-gap);
}
.root .main-panel .group .group-title {
  font-size: var(--uui-dt-columns_config_modal-group_title-font-size);
  line-height: var(--uui-dt-columns_config_modal-group_title-line-height);
  font-weight: var(--uui-dt-columns_config_modal-group_title-font-weight);
}
.root .main-panel .group:not(:first-child) {
  padding-top: calc(var(--uui-grid-unit) * 2);
}
.root .main-panel .group-items {
  flex-direction: column;
}
.root .main-panel .no-data {
  flex-direction: column;
  top: calc(50% - 35px);
  position: relative;
}
.root .main-panel .no-data .no-data-title {
  font-size: var(--uui-dt-columns_config_modal-no_data_title-font-size);
  line-height: var(--uui-dt-columns_config_modal-no_data_title-line-height);
  font-weight: var(--uui-dt-columns_config_modal-no_data_title-font-weight);
}
.root .main-panel .no-data .no-data-sub-title {
  font-size: var(--uui-dt-columns_config_modal-no_data_sub_title-font-size);
  line-height: var(--uui-dt-columns_config_modal-no_data_sub_title-line-height);
  font-weight: var(--uui-dt-columns_config_modal-no_data_sub_title-font-weight);
}
.root .main-panel .h-divider {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: var(--uui-grid-unit) var(--uui-dt-columns_config_modal-padding);
}
.root .main-panel .h-divider::before {
  content: "";
  height: 1px;
  display: block;
  background: var(--uui-divider-light);
}
.root .search-area {
  min-height: var(--uui-dt-columns_config_modal-search_area-min-height);
  column-gap: var(--uui-dt-columns_config_modal-horizontal-gap);
  padding: 0 var(--uui-dt-columns_config_modal-padding);
  padding-bottom: var(--uui-grid-unit);
}
.root .subgroup-accordion :global(.uui-accordion-toggler):focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: -2px;
}
.root .subgroup-accordion .subgroup {
  cursor: pointer;
  text-transform: uppercase;
  min-height: var(--uui-dt-columns_config_modal-subgroup-min-height);
  padding: 0 var(--uui-dt-columns_config_modal-padding);
  column-gap: var(--uui-dt-columns_config_modal-horizontal-gap);
}
.root .subgroup-accordion .subgroup .subgroup-title {
  font-size: var(--uui-dt-columns_config_modal-subgroup_title-font-size);
  line-height: var(--uui-dt-columns_config_modal-subgroup_title-line-height);
  font-weight: var(--uui-dt-columns_config_modal-subgroup_title-font-weight);
}
.root .subgroup-accordion .subgroup :global(.uui-icon) {
  fill: var(--uui-text-tertiary);
}