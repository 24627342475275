@use '~@epam/promo/assets/styles/fonts' as *;
@use '~@epam/promo/assets/styles/colors' as *;

.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #FAFAFC;
}

.table {
    height: calc(100% - 60px);
}

.details {
    height: 100%;
}

:global(.uui-table-row) {
    .configItem {
        visibility: hidden;

        &.showButton {
            visibility: visible;
        }
    }

    &:hover {
        .configItem {
            visibility: visible;
        }
    }
}

@keyframes show-info-panel {
    0% {
        transform: translate3d(100%, 0, 0);
        left: 100%;
    }

    100% {
        transform: translate3d(0, 0, 0);
        left: 0%;
    }
}

@keyframes hide-info-panel {
    0% {
        transform: translate3d(0, 0, 0);
        left: 0;
    }

    100% {
        transform: translate3d(100%, 0, 0);
        left: -100%;
    }
}

.sidebar {
    position: fixed;
    left: 100%;
    right: 0;
    top: 60px;
    bottom: 0;
    z-index: 1000;
    &:global(.show) {
        animation: show-info-panel 500ms;
        animation-fill-mode: forwards;
    }

    &:global(.hide) {
        animation: hide-info-panel 500ms;
        animation-fill-mode: forwards;
    }
}
