.root {
  --uui-dt-cell-size: var(--uui-size);
  padding-inline-start: var(--uui-dt-cell-padding-start);
  padding-inline-end: var(--uui-dt-cell-padding-end);
}
.root.wrapper {
  display: flex;
  align-items: flex-start;
}
.root:global(.data-table-cell).align-widgets-top div:global(.uui-checkbox) {
  margin-top: calc(var(--uui-dt-cell-size) / 2 - var(--uui-size) / 2);
}
.root.align-widgets-center :global(.uui-checkbox-container) {
  align-self: center;
}
.root:global(.uui-invalid) {
  background-color: var(--uui-dt-cell-bg-invalid);
}

:global(.uui-dt-vertical-cell-border) {
  border-inline-start-color: var(--uui-dt-border);
  border-inline-end-color: var(--uui-dt-border);
}