.root {
  --uui-file_card-border: var(--uui-control-border);
  --uui-file_card-border-error: var(--uui-error-50);
  --uui-file_card-text-error: var(--uui-error-70);
  --uui-file_card-icon-default: var(--uui-icon);
  --uui-file_card-icon-error: var(--uui-error-70);
  --uui-file_card-icon-doc: #2B579A;
  --uui-file_card-icon-xls: #217346;
  --uui-file_card-icon-pdf: #FA0F01;
  --uui-file_card-icon-mov: #5214CC;
  --uui-file_card-icon-img: #E67E17;
  --uui-file_card-font-error: var(--uui-font);
  --uui-file_card-border-radius: var(--uui-border-radius);
  --uui-file_card-icon-size: 18px;
}
.root:global(.uui-file_card) {
  box-sizing: border-box;
  min-height: 48px;
  padding: 5px;
  border: 1px solid var(--uui-file_card-border);
  border-radius: var(--uui-file_card-border-radius);
}
.root:global(.uui-file_card) .file-name {
  white-space: nowrap;
}
.root:global(.uui-loading) .default-color,
.root:global(.uui-loading) .doc-color,
.root:global(.uui-loading) .xls-color,
.root:global(.uui-loading) .pdf-color,
.root:global(.uui-loading) .movie-color,
.root:global(.uui-loading) .img-color {
  fill: var(--uui-icon-disabled);
}
.root:global(.uui-error) {
  border: 1px solid var(--uui-file_card-border-error);
}
.root .default-color {
  fill: var(--uui-file_card-icon-default);
}
.root .doc-color {
  fill: var(--uui-file_card-icon-doc);
}
.root .xls-color {
  fill: var(--uui-file_card-icon-xls);
}
.root .pdf-color {
  fill: var(--uui-file_card-icon-pdf);
}
.root .mov-color {
  fill: var(--uui-file_card-icon-mov);
}
.root .img-color {
  fill: var(--uui-file_card-icon-img);
}
.root .error-block {
  font-family: var(--uui-file_card-font-error);
  font-weight: 400;
  font-size: 12px;
  color: var(--uui-file_card-text-error);
  display: inline-flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
}
.root .error-block :global(.uui-icon) {
  margin-right: 3px;
  fill: var(--uui-file_card-icon-error);
}
.root .icons-block {
  min-width: 18px;
  flex-basis: 0;
}
.root .icons-block :global(.uui-icon) svg {
  height: var(--uui-file_card-icon-size);
  width: inherit;
}