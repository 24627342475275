.root {
  --uui-link_btn-text: var(--uui-color-50);
  --uui-link_btn-text-hover: var(--uui-color-60);
  --uui-link_btn-text-active: var(--uui-color-70);
  --uui-link_btn-text-disabled: var(--uui-text-disabled);
  --uui-link-button-font: var(--uui-font);
  --uui-link-button-font-weight-semibold: 600;
  --uui-link-button-font-weight-regular: 400;
  --uui-link-button-min-height: var(--uui-size);
  --uui-link-button-min-width: var(--uui-size);
  --uui-link-button-gap: var(--uui-horizontal-gap);
  --uui-link-button-caption-padding: calc((var(--uui-link-button-min-height) - var(--uui-link-button-line-height)) / 2);
  --uui-link-button-icon-height: var(--uui-icon-size);
  --uui-link-button-line-height: var(--uui-line-height);
  --uui-link-button-font-size: var(--uui-font-size);
  --uui-link-button-focus-radius: var(--uui-focus-radius);
  min-width: var(--uui-link-button-min-width);
  text-decoration: none;
  outline: none;
  border: 0;
  background: none;
  box-sizing: border-box;
  appearance: none;
  align-items: unset;
  padding: unset;
  margin: unset;
  justify-content: unset;
  overflow: hidden;
  display: flex;
  position: relative;
}
.root:hover {
  text-decoration: none;
}
.root:global(.-clickable) {
  cursor: pointer;
}
.root:global(.uui-disabled) {
  cursor: default;
}
.root:global(.uui-button-box) {
  background-color: transparent;
  color: var(--uui-link_btn-text);
  fill: var(--uui-link_btn-text);
  min-height: var(--uui-link-button-min-height);
  min-width: auto;
}
.root:global(.uui-button-box):global(.-clickable:not(.uui-disabled)):hover {
  color: var(--uui-link_btn-text-hover);
  fill: var(--uui-link_btn-text-hover);
}
.root:global(.uui-button-box):global(.-clickable:not(.uui-disabled)):active {
  color: var(--uui-link_btn-text-active);
  fill: var(--uui-link_btn-text-active);
}
.root:global(.uui-button-box):global(.-clickable:not(.uui-disabled)):focus {
  box-shadow: none;
}
.root:global(.uui-button-box):global(.-clickable:not(.uui-disabled)):focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: var(--uui-link-button-focus-radius);
}
.root:global(.uui-button-box):global(.uui-disabled) {
  color: var(--uui-link_btn-text-disabled);
  fill: var(--uui-link_btn-text-disabled);
  background-color: transparent;
}
.root:global(.uui-button-box):global(.uui-disabled):hover {
  background-color: transparent;
}
.root :global(.uui-caption) {
  font-family: var(--uui-link-button-font);
  font-weight: var(--uui-link-button-font-weight, normal);
  line-height: var(--uui-link-button-line-height);
  font-size: var(--uui-link-button-font-size);
  padding-top: var(--uui-link-button-caption-padding);
  padding-bottom: var(--uui-link-button-caption-padding);
}
.root :global(.uui-caption):not(:first-child) {
  padding-inline-start: var(--uui-link-button-gap);
}
.root :global(.uui-caption):not(:last-child) {
  padding-inline-end: var(--uui-link-button-gap);
}
.root :global(.uui-caption):global(.uui-underline-solid) {
  text-decoration: underline;
  text-underline-offset: 0.2em;
}
.root :global(.uui-caption):global(.uui-underline-dashed) {
  text-decoration: underline dashed;
  text-underline-offset: 0.2em;
}
.root :global(.uui-caption):global(.uui-link-button-weight-semibold) {
  font-weight: var(--uui-link-button-font-weight-semibold);
}
.root :global(.uui-caption):global(.uui-link-button-weight-regular) {
  font-weight: var(--uui-link-button-font-weight-regular);
}
.root :global(.uui-icon) svg {
  height: var(--uui-link-button-icon-height);
  width: inherit;
}