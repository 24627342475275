.date-input {
  min-width: 0;
  padding: var(--uui-rdtp-date_input-padding);
}
.date-input:global(.uui-input-box) {
  border-width: 0;
  height: var(--uui-rdtp-date_input-height);
}

.root {
  --uui-rdtp-bg: var(--uui-control-bg);
  --uui-rdtp-border: var(--uui-control-border);
  --uui-rdtp-border-hover: var(--uui-control-border-hover);
  --uui-rdtp-border-active: var(--uui-control-border-focus);
  --uui-rdtp-border-error: var(--uui-error-50);
  --uui-rdtp-border-radius: var(--uui-border-radius);
  --uui-rdtp-border-width: var(--uui-border-width);
  --uui-rdtp-date_input-height: calc(var(--uui-size) - var(--uui-border-width) * 2);
  --uui-rdtp-date_input-padding: 0 5px;
  display: flex;
  min-width: 0;
  background-color: var(--uui-rdtp-bg);
  border: var(--uui-rdtp-border-width) solid var(--uui-rdtp-border);
  border-radius: var(--uui-rdtp-border-radius);
}
.root > :first-child {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}
.root > :last-child {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}
.root .separator {
  display: flex;
  flex-shrink: 0;
  background: var(--uui-rdtp-border);
  width: var(--uui-rdtp-border-width);
}
.root:hover {
  border-color: var(--uui-rdtp-border-hover);
}
.root:hover .separator {
  background: var(--uui-rdtp-border-hover);
}
.root:hover:global(.uui-disabled), .root:hover:global(.uui-readonly) {
  border-color: var(--uui-rdtp-border);
}
.root:hover:global(.uui-disabled) .separator, .root:hover:global(.uui-readonly) .separator {
  background: var(--uui-rdtp-border);
}
.root:global(.uui-focus) {
  border-color: var(--uui-rdtp-border);
}
.root:global(.uui-focus) .separator {
  background: var(--uui-rdtp-border-active);
}
.root:global(.uui-focus) .date-input:hover {
  outline: var(--uui-rdtp-border-width) solid var(--uui-rdtp-border-hover);
}
.root:global(.uui-focus) .date-input:global(.uui-focus) {
  outline: var(--uui-rdtp-border-width) solid var(--uui-rdtp-border-active);
}
.root:global(.uui-readonly).mode-form {
  background-color: var(--uui-control-bg-disabled);
  border-color: var(--uui-control-border-disabled);
}
.root:global(.uui-readonly).mode-form:hover {
  border-color: var(--uui-control-border-disabled);
}
.root:global(.uui-readonly).mode-form :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-readonly).mode-cell {
  background-color: transparent;
  border-color: transparent;
}
.root:global(.uui-readonly).mode-cell:hover {
  background-color: transparent;
  border-color: transparent;
}
.root:global(.uui-readonly).mode-cell :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-readonly) .date-input:global(.uui-focus) {
  box-shadow: none;
}
.root:global(.uui-invalid) {
  border-color: var(--uui-rdtp-border-error);
}
.root:global(.uui-invalid) .separator {
  background: var(--uui-rdtp-border-error);
}