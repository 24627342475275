.root {
  --uui-picker_toggler-bg: var(--uui-control-bg);
  --uui-picker_toggler-border: var(--uui-control-border);
  --uui-picker_toggler-border-hover: var(--uui-control-border-hover);
  --uui-picker_toggler-border-active: var(--uui-control-border-focus);
  --uui-picker_toggler-border-error: var(--uui-error-50);
  --uui-picker_toggler-placeholder: var(--uui-control-placeholder);
  --uui-picker_toggler-placeholder-disabled: var(--uui-control-placeholder-disabled);
  --uui-picker_toggler-placeholder-readonly: var(--uui-control-placeholder-disabled);
  --uui-picker_toggler-text: var(--uui-control-text);
  --uui-picker_toggler-text-disabled: var(--uui-control-text-disabled);
  --uui-picker_toggler-icon: var(--uui-control-icon);
  --uui-picker_toggler-icon-disabled: var(--uui-control-icon-disabled);
  --uui-picker_toggler-icon-clickable: var(--uui-icon);
  --uui-picker_toggler-icon-clickable-hover: var(--uui-icon-hover);
  --uui-picker_toggler-font: var(--uui-font);
  --uui-picker_toggler-font-weight: 400;
  --uui-picker_toggler-border-radius: var(--uui-border-radius);
  --uui-picker_toggler-min-height: var(--uui-size);
  --uui-picker_toggler-min-width: var(--uui-size);
  --uui-picker_toggler-border-width: var(--uui-border-width);
  --uui-picker_toggler-horizontal-padding: var(--uui-horizontal-padding);
  --uui-picker_toggler-vertical-padding: var(--uui-vertical-padding);
  --uui-picker_toggler-inner-padding: var(--uui-horizontal-gap);
  --uui-picker_toggler-icon-height: var(--uui-icon-size);
  --uui-picker_toggler-line-height: var(--uui-line-height);
  --uui-picker_toggler-font-size: var(--uui-font-size);
  --uui-picker_toggler-horizontal-gap: 3px;
  --uui-picker_toggler-vertical-gap: 2px;
  --uui-picker_toggler-multiline-gaps: var(--uui-picker_toggler-vertical-gap) var(--uui-picker_toggler-horizontal-gap);
  padding: calc(var(--uui-picker_toggler-vertical-padding) - var(--uui-picker_toggler-border-width)) calc(var(--uui-picker_toggler-horizontal-padding) - var(--uui-picker_toggler-border-width));
  width: 100%;
  min-width: var(--uui-picker_toggler-min-width);
  box-sizing: border-box;
  border-style: solid;
  border-radius: var(--uui-picker_toggler-border-radius);
}
.root:global(.uui-input-box) {
  min-height: var(--uui-picker_toggler-min-height);
  border-width: var(--uui-picker_toggler-border-width);
}
.root div:first-child {
  gap: var(--uui-picker_toggler-multiline-gaps);
}
.root div:first-child :global(.uui-icon):first-child {
  padding-inline-end: var(--uui-picker_toggler-horizontal-gap);
}
.root :global(.uui-input) {
  line-height: var(--uui-picker_toggler-line-height);
  font-size: var(--uui-picker_toggler-font-size);
  padding-left: var(--uui-picker_toggler-inner-padding);
  padding-right: var(--uui-picker_toggler-inner-padding);
  padding-top: calc((var(--uui-picker_toggler-min-height) - var(--uui-picker_toggler-line-height) - var(--uui-picker_toggler-vertical-padding) * 2) / 2);
  padding-bottom: calc((var(--uui-picker_toggler-min-height) - var(--uui-picker_toggler-line-height) - var(--uui-picker_toggler-vertical-padding) * 2) / 2);
  font-family: var(--uui-picker_toggler-font);
  font-weight: var(--uui-picker_toggler-font-weight, normal);
  color: var(--uui-picker_toggler-text);
}
.root :global(.uui-input)::placeholder {
  color: var(--uui-picker_toggler-placeholder);
}
.root :global(.uui-input)::-moz-placeholder {
  opacity: 1;
}
.root :global(.uui-input):not(:first-child) {
  padding-left: calc(var(--uui-picker_toggler-inner-padding) - 3px);
}
.root :global(.uui-input):not(:last-child) {
  padding-right: calc(var(--uui-picker_toggler-inner-padding) - 3px);
}
.root :global(.uui-caption) {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--uui-picker_toggler-font);
  font-weight: var(--uui-picker_toggler-font-weight, normal);
  align-self: center;
  white-space: nowrap;
}
.root :global(.uui-placeholder) {
  color: var(--uui-picker_toggler-text);
}
.root :global(.uui-placeholder)::placeholder {
  color: var(--uui-picker_toggler-text);
}
.root :global(.uui-placeholder)::-moz-placeholder {
  opacity: 1;
}
.root :global(.uui-icon-cancel) {
  margin-left: var(--uui-picker_toggler-inner-padding);
}
.root :global(.uui-icon) svg {
  height: var(--uui-picker_toggler-icon-height);
  width: inherit;
}
.root.mode-form {
  background-color: var(--uui-picker_toggler-bg);
  border-color: var(--uui-picker_toggler-border);
  fill: var(--uui-picker_toggler-icon);
}
.root.mode-form :global(.uui-icon):global(.-clickable):global(.uui-enabled), .root.mode-form :global(.uui-icon-dropdown):global(.uui-enabled) {
  fill: var(--uui-picker_toggler-icon-clickable);
}
.root.mode-form :global(.uui-icon):global(.-clickable):global(.uui-enabled):hover, .root.mode-form :global(.uui-icon-dropdown):global(.uui-enabled):hover {
  fill: var(--uui-picker_toggler-icon-clickable-hover);
}
.root.mode-form :global(.uui-icon):global(.-clickable):global(.uui-disabled), .root.mode-form :global(.uui-icon-dropdown):global(.uui-disabled) {
  fill: var(--uui-picker_toggler-icon-disabled);
}
.root.mode-form:hover {
  border-color: var(--uui-picker_toggler-border-hover);
}
.root.mode-form:global(.uui-focus) {
  border-color: var(--uui-picker_toggler-border-active);
  outline: none;
}
.root.mode-cell {
  background-color: transparent !important;
  border-color: transparent;
  border-radius: 0;
  fill: var(--uui-picker_toggler-icon);
}
.root.mode-cell :global(.uui-picker_toggler-actions) :global(.uui-icon),
.root.mode-cell :global(.uui-button-box) :global(.uui-icon):global(.-clickable) {
  cursor: pointer;
}
.root.mode-cell :global(.uui-picker_toggler-actions) :global(.uui-icon):global(.uui-enabled),
.root.mode-cell :global(.uui-button-box) :global(.uui-icon):global(.-clickable):global(.uui-enabled) {
  fill: transparent;
}
.root.mode-cell :global(.uui-picker_toggler-actions) :global(.uui-icon):global(.uui-disabled),
.root.mode-cell :global(.uui-button-box) :global(.uui-icon):global(.-clickable):global(.uui-disabled) {
  fill: transparent;
}
.root.mode-cell:hover :global(.uui-icon):global(.-clickable):global(.uui-enabled), .root.mode-cell:hover :global(.uui-icon-dropdown):global(.uui-enabled), .root.mode-cell:global(.uui-focus) :global(.uui-icon):global(.-clickable):global(.uui-enabled), .root.mode-cell:global(.uui-focus) :global(.uui-icon-dropdown):global(.uui-enabled) {
  fill: var(--uui-picker_toggler-icon-clickable);
}
.root.mode-cell:hover :global(.uui-icon):global(.-clickable):global(.uui-enabled):hover, .root.mode-cell:hover :global(.uui-icon-dropdown):global(.uui-enabled):hover, .root.mode-cell:global(.uui-focus) :global(.uui-icon):global(.-clickable):global(.uui-enabled):hover, .root.mode-cell:global(.uui-focus) :global(.uui-icon-dropdown):global(.uui-enabled):hover {
  fill: var(--uui-picker_toggler-icon-clickable-hover);
}
.root.mode-cell:hover :global(.uui-icon):global(.-clickable):global(.uui-disabled), .root.mode-cell:hover :global(.uui-icon-dropdown):global(.uui-disabled), .root.mode-cell:global(.uui-focus) :global(.uui-icon):global(.-clickable):global(.uui-disabled), .root.mode-cell:global(.uui-focus) :global(.uui-icon-dropdown):global(.uui-disabled) {
  fill: transparent;
}
.root.mode-cell:global(.uui-focus) {
  outline: none;
}
.root.mode-inline {
  background-color: initial;
  border-color: transparent;
  fill: var(--uui-picker_toggler-icon-clickable);
}
.root.mode-inline:hover {
  border-color: var(--uui-picker_toggler-border-hover);
}
.root.mode-inline:global(.uui-focus) {
  border-color: var(--uui-picker_toggler-border-active);
  outline: none;
}
.root:global(.uui-invalid).mode-form, .root:global(.uui-invalid).mode-inline {
  border-color: var(--uui-picker_toggler-border-error);
}
.root:global(.uui-readonly).mode-form {
  background-color: var(--uui-control-bg-disabled);
  border-color: var(--uui-control-border-disabled);
}
.root:global(.uui-readonly).mode-form:hover {
  border-color: var(--uui-control-border-disabled);
}
.root:global(.uui-readonly).mode-form :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-readonly).mode-cell {
  background-color: transparent;
  border-color: transparent;
}
.root:global(.uui-readonly).mode-cell:hover {
  background-color: transparent;
  border-color: transparent;
}
.root:global(.uui-readonly).mode-cell :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-readonly) :global(.uui-placeholder):global(.uui-input)::placeholder {
  color: var(--uui-picker_toggler-text);
}
.root:global(.uui-readonly) :global(.uui-icon-dropdown), .root:global(.uui-readonly) :global(.uui-icon-cancel) {
  visibility: hidden;
}
.root:global(.uui-disabled) {
  fill: var(--uui-picker_toggler-icon-disabled);
}
.root:global(.uui-disabled).mode-form {
  cursor: default;
  background-color: var(--uui-control-bg-disabled);
  color: var(--uui-control-text-disabled);
  fill: var(--uui-control-text-disabled);
  border-color: var(--uui-control-border-disabled);
}
.root:global(.uui-disabled).mode-form :global(.uui-input) {
  color: var(--uui-control-text-disabled);
}
.root:global(.uui-disabled).mode-form :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-disabled).mode-form:hover, .root:global(.uui-disabled).mode-form:active, .root:global(.uui-disabled).mode-form:focus {
  box-shadow: none;
  background-color: var(--uui-control-bg-disabled);
  color: var(--uui-control-text-disabled);
  fill: var(--uui-control-text-disabled);
  border-color: var(--uui-control-border-disabled);
}
.root:global(.uui-disabled).mode-form:hover :global(.uui-input), .root:global(.uui-disabled).mode-form:active :global(.uui-input), .root:global(.uui-disabled).mode-form:focus :global(.uui-input) {
  color: var(--uui-control-text-disabled);
}
.root:global(.uui-disabled).mode-form:hover :global(.uui-input)::placeholder, .root:global(.uui-disabled).mode-form:active :global(.uui-input)::placeholder, .root:global(.uui-disabled).mode-form:focus :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-disabled).mode-cell {
  cursor: default;
  background-color: transparent;
  color: var(--uui-control-text-disabled);
  fill: var(--uui-control-text-disabled);
  border-color: transparent;
}
.root:global(.uui-disabled).mode-cell :global(.uui-input) {
  color: var(--uui-control-text-disabled);
}
.root:global(.uui-disabled).mode-cell :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-disabled).mode-cell:hover, .root:global(.uui-disabled).mode-cell:active, .root:global(.uui-disabled).mode-cell:focus {
  box-shadow: none;
  background-color: transparent;
  color: var(--uui-control-text-disabled);
  fill: var(--uui-control-text-disabled);
  border-color: transparent;
}
.root:global(.uui-disabled).mode-cell:hover :global(.uui-input), .root:global(.uui-disabled).mode-cell:active :global(.uui-input), .root:global(.uui-disabled).mode-cell:focus :global(.uui-input) {
  color: var(--uui-control-text-disabled);
}
.root:global(.uui-disabled).mode-cell:hover :global(.uui-input)::placeholder, .root:global(.uui-disabled).mode-cell:active :global(.uui-input)::placeholder, .root:global(.uui-disabled).mode-cell:focus :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-disabled) :global(.uui-input) {
  cursor: default;
}
.root:global(.uui-disabled) :global(.uui-icon-dropdown), .root:global(.uui-disabled) :global(.uui-icon-cancel) {
  visibility: hidden;
}