.sub-header-wrapper {
  padding: 0 24px;
}

.switch {
  margin-bottom: 12px;
}

.no-found-modal-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.no-found-modal-container .no-found-modal-container-icon {
  align-items: center;
  margin-bottom: 12px;
}
.no-found-modal-container .no-found-modal-container-text {
  margin: 0;
  padding: 0;
}