.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 12px 0;
  margin-bottom: 9px;
}
.header .removeButton {
  padding-left: 24px;
  margin-left: auto;
}
.header .removeButton :global(.uui-caption) {
  font-size: 14px;
}
.header.with-search {
  margin-bottom: 0;
}