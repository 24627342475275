.root {
  --uui-numeric_input-icon-clickable: var(--uui-icon);
  --uui-numeric_input-icon-clickable-hover: var(--uui-icon-hover);
}
.root:global(.uui-input-box) {
  padding-inline-end: 0;
  min-width: 40px;
}
.root:global(.uui-input-box):global(.uui-numeric-input-without-arrows) {
  padding-inline-end: calc(var(--uui-horizontal-padding) - var(--uui-border-width));
}
.root:global(.uui-input-box) :global(.uui-numeric-input-up-button) {
  transform: rotate(180deg);
}
.root:global(.uui-input-box) :global(.uui-numeric-input-up-button):hover svg {
  fill: var(--uui-numeric_input-icon-clickable-hover);
}
.root:global(.uui-input-box) :global(.uui-numeric-input-down-button):hover svg {
  fill: var(--uui-numeric_input-icon-clickable-hover);
}
.root:global(.uui-input-box) :global(.uui-input.uui-value-in-placeholder)::placeholder {
  color: inherit;
}
.root:global(.uui-input-box) :global(.uui-icon) svg {
  margin-top: -2px;
}
.root:global(.uui-input-box) :global(.uui-numeric-input-button-group) > * {
  height: calc((var(--uui-size) - var(--uui-border-width) * 2) / 2);
}
.root:global(.uui-disabled) :global(.uui-numeric-input-button-group) > *:hover, .root:global(.uui-disabled) :global(.uui-numeric-input-button-group) > *:focus {
  cursor: default;
  background-color: transparent;
}
.root:global(.uui-readonly).mode-form {
  background-color: var(--uui-control-bg-disabled);
  border-color: var(--uui-control-border-disabled);
}
.root:global(.uui-readonly).mode-form:hover {
  border-color: var(--uui-control-border-disabled);
}
.root:global(.uui-readonly).mode-form :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-readonly).mode-cell {
  background-color: transparent;
  border-color: transparent;
}
.root:global(.uui-readonly).mode-cell:hover {
  background-color: transparent;
  border-color: transparent;
}
.root:global(.uui-readonly).mode-cell :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}