.root {
  --uui-tab_btn-text: var(--uui-text-primary);
  --uui-tab_btn-text-hover: var(--uui-primary-50);
  --uui-tab_btn-text-active: var(--uui-primary-50);
  --uui-tab_btn-text-disabled: var(--uui-text-disabled);
  --uui-tab_btn-font: var(--uui-font);
  --uui-tab_btn-font-weight: var(--uui-font-weight, 600);
  --uui-tab_btn-height: var(--uui-size);
  --uui-tab_btn-min-width: var(--uui-size);
  --uui-tab_btn-padding: var(--uui-horizontal-padding);
  --uui-tab_btn-gap: var(--uui-horizontal-gap);
  --uui-tab_btn-horizontal-caption-padding: var(--uui-horizontal-gap);
  --uui-tab_btn-vertical-caption-padding: calc((var(--uui-tab_btn-height) - var(--uui-tab_btn-line-height)) / 2);
  --uui-tab_btn-icon-height: var(--uui-icon-size);
  --uui-tab_btn-line-height: var(--uui-line-height);
  --uui-tab_btn-font-size: var(--uui-font-size);
  text-decoration: none;
  outline: none;
  border: 0;
  background: none;
  box-sizing: border-box;
  appearance: none;
  align-items: unset;
  padding: unset;
  margin: unset;
  justify-content: unset;
  overflow: hidden;
  display: flex;
  position: relative;
}
.root:hover {
  text-decoration: none;
}
.root:global(.-clickable) {
  cursor: pointer;
}
.root:global(.uui-disabled) {
  cursor: default;
}
.root {
  cursor: pointer;
  padding-inline-start: var(--uui-tab_btn-padding);
  padding-inline-end: var(--uui-tab_btn-padding);
  bottom: -1px;
  align-items: center;
  font-family: var(--uui-tab_btn-font);
  font-weight: var(--uui-tab_btn-font-weight);
  border-bottom: var(--uui-tab-line-width) solid transparent;
  height: var(--uui-tab_btn-height);
  min-width: var(--uui-tab_btn-min-width);
  column-gap: var(--uui-tab_btn-gap);
}
.root {
  color: var(--uui-tab_btn-text);
  fill: var(--uui-tab_btn-text);
  background-color: transparent;
}
.root:hover {
  color: var(--uui-tab_btn-text-active);
  fill: var(--uui-tab_btn-text-active);
}
.root:global(.uui-active) {
  color: var(--uui-tab_btn-text-active);
  fill: var(--uui-tab_btn-text-active);
  border-color: var(--uui-tab_btn-text-active);
}
.root svg {
  height: var(--uui-tab_btn-icon-height);
  width: inherit;
}
.root:global(.-clickable):focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: calc(0 - var(--uui-focus-outline-width));
}
.root:global(.uui-disabled) {
  color: var(--uui-tab_btn-text-disabled);
  fill: var(--uui-tab_btn-text-disabled);
  background-color: transparent;
}
.root:global(.uui-disabled):hover {
  color: var(--uui-tab_btn-text-disabled);
  fill: var(--uui-tab_btn-text-disabled);
}
.root:global(.uui-disabled):global(.uui-active) {
  color: var(--uui-tab_btn-text-disabled);
  fill: var(--uui-tab_btn-text-disabled);
  border-color: var(--uui-tab_btn-text-disabled);
}
.root:global(.uui-button-box) {
  min-width: min-content;
}
.root :global(.uui-caption) {
  display: flex;
  position: relative;
  line-height: var(--uui-tab_btn-line-height);
  font-size: var(--uui-tab_btn-font-size);
  text-wrap: nowrap;
  padding-top: var(--uui-tab_btn-vertical-caption-padding);
  padding-bottom: var(--uui-tab_btn-vertical-caption-padding);
  padding-inline-start: var(--uui-tab_btn-horizontal-caption-padding);
  padding-inline-end: var(--uui-tab_btn-horizontal-caption-padding);
}
.root :global(.uui-caption) .withNotify {
  position: relative;
  top: calc(0px - var(--uui-notify-size) / 2);
  height: var(--uui-notify-size);
  width: var(--uui-notify-size);
  background: var(--uui-critical-50);
  box-shadow: 0 1px 8px 0 var(--uui-critical-50);
  border-radius: 50%;
}
.root:not(:global(.uui-vertical-tab-button)) :global(.uui-caption) {
  padding-bottom: calc(var(--uui-tab_btn-vertical-caption-padding) - var(--uui-tab-line-width));
}
.root:not(:global(.uui-vertical-tab-button)) :global(.uui-count_indicator) {
  margin-bottom: calc(var(--uui-tab_btn-vertical-caption-padding) - var(--uui-tab-line-width));
}
.root:not(:global(.uui-vertical-tab-button)) :global(.uui-icon) {
  margin-bottom: calc(0px - var(--uui-tab-line-width));
}