.root {
  --uui-dt-header-row-config-icon-padding: 24px;
}
.root :global(.uui-table-column-group-wrapper) :global(.uui-table-header-cell) {
  --uui-dt-header-cell-border: var(--uui-dt-border);
}

:global(.config-icon) {
  position: absolute;
  height: 100%;
  inset-inline-end: var(--uui-dt-header-row-config-icon-padding);
  z-index: 2;
}
:global(.config-icon) :global(.uui-icon) svg {
  height: var(--uui-size);
  width: inherit;
}

:global(.uui-table-header-row) :global(.uui-table-fixed-section), :global(.uui-table-header-row) :global(.uui-table-scrolling-section) {
  border-bottom-color: var(--uui-dt-border);
}

:global(.uui-table-header-row) {
  --uui-dt-row-bg: var(--uui-dt-header-row-bg);
  --uui-dt-row-bg-hover: var(--uui-dt-header-row-bg-hover);
}