.root {
  --uui-labeled_input: var(--uui-text-primary);
  --uui-labeled_input-disabled: var(--uui-text-disabled);
  --uui-labeled_input-error: var(--uui-error-50);
  --uui-labeled_input-fill: var(--uui-icon);
  --uui-labeled_input-info-fill: var(--uui-icon);
  --uui-labeled_input-optional-text: var(--uui-text-tertiary);
  --uui-labeled_input-asterisk: var(--uui-error-50);
  --uui-labeled_input-char-counter: var(--uui-text-tertiary);
  --uui-labeled_input-footnote: var(--uui-text-tertiary);
  --uui-labeled_input-sidenote: var(--uui-text-tertiary);
  --uui-labeled_input-font: var(--uui-font);
  --uui-labeled_input-font-weight: 600;
  --uui-labeled_input-optional-font: var(--uui-font);
  --uui-labeled_input-optional-font-weight: 400;
  --uui-labeled_input-optional-font-style: italic;
  --uui-labeled_input-invalid-msg-font: var(--uui-font);
  --uui-labeled_input-invalid-msg-font-weight: 400;
  --uui-labeled_input-char-counter-font: var(--uui-font);
  --uui-labeled_input-char-counter-font-weight: 400;
  --uui-labeled_input-footnote-font: var(--uui-font);
  --uui-labeled_input-footnote-font-weight: 400;
  --uui-labeled_input-sidenote-font: var(--uui-font);
  --uui-labeled_input-sidenote-font-weight: 400;
  --uui-labeled_input-font-size: var(--uui-font-size);
  --uui-labeled_input-line-height: var(--uui-line-height);
  --uui-labeled_input-icon-size: var(--uui-icon-size);
  --uui-labeled_input-horizontal-gap: var(--uui-horizontal-gap);
  --uui-labeled_input-vertical-gap: var(--uui-vertical-gap);
  --uui-labeled_input-sidenote-font-size: 12px;
  --uui-labeled_input-sidenote-line-height: 18px;
  --uui-labeled_input-footnote-font-size: 12px;
  --uui-labeled_input-footnote-line-height: 18px;
  --uui-labeled_input-optional-font-size: 12px;
  --uui-labeled_input-optional-line-height: 18px;
  --uui-labeled_input-invalid-msg-font-size: 12px;
  --uui-labeled_input-invalid-msg-line-height: 18px;
  --uui-labeled_input-char-counter-font-size: 12px;
  --uui-labeled_input-char-counter-line-height: 18px;
  width: 100%;
}
.root :global(.uui-label) {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--uui-labeled_input-font);
  font-weight: var(--uui-labeled_input-font-weight);
  font-size: var(--uui-labeled_input-font-size);
  line-height: var(--uui-labeled_input-line-height);
  color: var(--uui-labeled_input);
  padding: calc(var(--uui-labeled_input-vertical-gap) * 2) 0;
}
.root :global(.uui-label) :global(.uui-labeled-input-info-icon) {
  flex-shrink: 0;
  margin-inline-start: var(--uui-labeled_input-horizontal-gap);
  fill: var(--uui-labeled_input-info-fill);
}
.root :global(.uui-label) :global(.uui-labeled-input-info-icon):global(.uui-icon) svg {
  height: var(--uui-labeled_input-icon-size);
  width: var(--uui-labeled_input-icon-size);
}
.root :global(.uui-label) :global(.uui-labeled-input-asterisk) {
  color: var(--uui-labeled_input-asterisk);
}
.root :global(.uui-label) :global(.uui-labeled-input-optional) {
  font-family: var(--uui-labeled_input-optional-font);
  font-weight: var(--uui-labeled_input-optional-font-weight);
  font-style: var(--uui-labeled_input-optional-font-style);
  font-size: var(--uui-labeled_input-optional-font-size);
  line-height: var(--uui-labeled_input-optional-line-height);
  color: var(--uui-labeled_input-optional-text);
  margin-inline-start: var(--uui-labeled_input-horizontal-gap);
}
.root :global(.uui-label-left) :global(.uui-label) {
  margin-inline-end: var(--uui-labeled_input-horizontal-gap);
}
.root :global(.uui-invalid-message) {
  line-height: var(--uui-labeled_input-invalid-msg-line-height);
  font-size: var(--uui-labeled_input-invalid-msg-font-size);
  color: var(--uui-labeled_input-error);
  font-family: var(--uui-labeled_input-invalid-msg-font);
  font-weight: var(--uui-labeled_input-invalid-msg-font-weight);
  margin-top: var(--uui-labeled_input-vertical-gap);
}
.root :global(.uui-labeled-input-char-counter) {
  flex-grow: 1;
  text-align: end;
  line-height: var(--uui-labeled_input-char-counter-line-height);
  font-size: var(--uui-labeled_input-char-counter-font-size);
  color: var(--uui-labeled_input-char-counter);
  font-family: var(--uui-labeled_input-char-counter-font);
  font-weight: var(--uui-labeled_input-char-counter-font-weight);
  margin-top: var(--uui-labeled_input-vertical-gap);
}
.root :global(.uui-labeled-input-footnote) {
  line-height: var(--uui-labeled_input-footnote-line-height);
  font-size: var(--uui-labeled_input-footnote-font-size);
  color: var(--uui-labeled_input-footnote);
  font-family: var(--uui-labeled_input-footnote-font);
  font-weight: var(--uui-labeled_input-footnote-font-weight);
  margin-top: var(--uui-labeled_input-vertical-gap);
}
.root :global(.uui-labeled-input-sidenote) {
  align-self: center;
  text-align: end;
  line-height: var(--uui-labeled_input-sidenote-line-height);
  font-size: var(--uui-labeled_input-sidenote-font-size);
  color: var(--uui-labeled_input-sidenote);
  font-family: var(--uui-labeled_input-sidenote-font);
  font-weight: var(--uui-labeled_input-sidenote-font-weight);
}