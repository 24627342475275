.root {
  --uui-main_menu-button-font: var(--uui-font);
  --uui-main_menu-button-font-weight: 600;
  --uui-main_menu-button-icon-size: 24px;
  flex-shrink: 0;
  color: var(--uui-main_menu-text);
  fill: var(--uui-main_menu-icon);
  text-decoration: none;
  outline: none;
  border: 0;
  background: none;
  box-sizing: border-box;
  appearance: none;
  align-items: unset;
  padding: unset;
  margin: unset;
  justify-content: unset;
  overflow: hidden;
  display: flex;
  position: relative;
}
.root:hover {
  text-decoration: none;
}
.root:global(.-clickable) {
  cursor: pointer;
}
.root:global(.uui-disabled) {
  cursor: default;
}
.root :global(.uui-caption), .root :global(.uui-input) {
  padding: 22px 6px;
  line-height: 16px;
  margin-bottom: -3px;
  font-family: var(--uui-main_menu-button-font);
  font-weight: var(--uui-main_menu-button-font-weight);
}
.root:global(.uui-button-box), .root:global(.uui-input-box) {
  min-height: 60px;
  padding-right: 6px;
  padding-left: 6px;
}
.root :global(.uui-caption) {
  white-space: nowrap;
  font-size: 16px;
}
.root :global(.uui-icon) svg {
  width: 18px;
}
.root.type-primary :global(.uui-icon) svg {
  height: var(--uui-main_menu-button-icon-size);
  width: inherit;
}
.root.type-secondary :global(.uui-icon) svg {
  height: var(--uui-main_menu-button-icon-size);
  width: inherit;
}
.root:global(.-clickable):hover, .root:global(.-clickable):focus {
  background-color: var(--uui-main_menu-hover);
  color: var(--uui-main_menu-text-hover);
  fill: var(--uui-main_menu-icon-hover);
}
.root:global(.uui-active) {
  background-color: var(--uui-main_menu-hover);
  border-bottom: 3px solid var(--uui-main_menu-border-active);
  border-image: var(--uui-main_menu-border-active-gradient, none);
  border-image-slice: 3;
  color: var(--uui-main_menu-text-active);
  fill: var(--uui-main_menu-icon-active);
}