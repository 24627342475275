:global(.uui-dropdownMenu-body) {
  --uui-dropdown_menu-bg: var(--uui-surface-main);
  --uui-dropdown_menu-shadow: var(--uui-shadow-level-2);
  --uui-dropdown_menu-splitter: var(--uui-divider-light);
  --uui-dropdown_menu-item-hover: var(--uui-surface-higher);
  --uui-dropdown_menu-item-active: var(--uui-surface-higher);
  --uui-dropdown_menu-item-text: var(--uui-text-primary);
  --uui-dropdown-selected_mark: var(--uui-info-50);
  --uui-dropdown_menu-body-font: var(--uui-font);
  --uui-dropdown_menu-body-font-weight: 400;
  --uui-dropdown_menu-item-font: var(--uui-font);
  --uui-dropdown_menu-item-font-weight: 400;
  color: var(--uui-text-primary);
  fill: var(--uui-icon);
  font-family: var(--uui-dropdown_menu-body-font);
  font-weight: var(--uui-dropdown_menu-body-font-weight);
  font-size: 14px;
  padding: 6px 0;
  background-color: var(--uui-dropdown_menu-bg);
  box-shadow: var(--uui-shadow-level-1);
  border-radius: var(--uui-dropdown_container-border-radius);
}

.submenu-root-item-rtl .icon-after {
  transform: rotate(90deg);
}

.submenu-root-item .icon-after {
  transform: rotate(-90deg);
}

.icon-check {
  color: transparent;
  fill: transparent;
}

.splitter-root {
  display: flex;
  width: 100%;
}
.splitter-root .splitter {
  width: 100%;
  border: 0;
  border-top: 1px solid var(--uui-dropdown_menu-splitter);
  height: 0;
}

.header-root {
  cursor: default;
  height: auto;
  font-size: 12px;
  padding: 3px 12px;
  color: var(--uui-text-secondary);
  fill: var(--uui-text-secondary);
  padding: 9px 12px;
  line-height: 18px;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  font-family: var(--uui-dropdown_menu-item-font);
  font-weight: var(--uui-dropdown_menu-item-font-weight);
}
.header-root:focus {
  outline: none;
}

.item-root {
  color: var(--uui-text-primary);
  fill: var(--uui-icon);
  padding: 9px 12px;
  line-height: 18px;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  font-family: var(--uui-dropdown_menu-item-font);
  font-weight: var(--uui-dropdown_menu-item-font-weight);
}
.item-root:focus {
  outline: none;
}
.item-root:global(.uui-active) {
  color: var(--uui-primary-50);
  fill: var(--uui-primary-50);
}
.item-root:global(.uui-active):hover, .item-root:global(.uui-active):focus {
  color: var(--uui-primary-50) !important;
  fill: var(--uui-primary-50) !important;
}
.item-root:global(.uui-active) .icon-check {
  color: var(--uui-primary-50);
  fill: var(--uui-primary-50);
}
.item-root .icon {
  justify-content: flex-start;
  flex-shrink: 0;
}
.item-root .icon:global(.uui-active) {
  color: var(--uui-primary-50);
  fill: var(--uui-primary-50);
}
.item-root .icon:global(.uui-active):hover, .item-root .icon:global(.uui-active):focus {
  color: var(--uui-primary-50) !important;
  fill: var(--uui-primary-50) !important;
}
.item-root .icon:global(.uui-active) .icon-check {
  color: var(--uui-primary-50);
  fill: var(--uui-primary-50);
}
.item-root .icon svg {
  width: 18px;
  height: 18px;
}
.item-root.link {
  display: flex;
  align-items: center;
}
.item-root.link:global(.uui-active) {
  color: var(--uui-primary-50);
  fill: var(--uui-primary-50);
}
.item-root.link:global(.uui-active):hover, .item-root.link:global(.uui-active):focus {
  color: var(--uui-primary-50) !important;
  fill: var(--uui-primary-50) !important;
}
.item-root.link:global(.uui-active) .icon-check {
  color: var(--uui-primary-50);
  fill: var(--uui-primary-50);
}
.item-root.link:focus {
  outline: none;
}
.item-root:global(.-clickable:not(.uui-disabled)):hover {
  background-color: var(--uui-dropdown_menu-item-hover);
  fill: var(--uui-icon-active);
  cursor: pointer;
}
.item-root:global(.-clickable:not(.uui-disabled)):focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: -2px;
}
.item-root:global(.uui-disabled) {
  pointer-events: none;
  color: var(--uui-text-disabled);
  fill: var(--uui-text-disabled);
}
.item-root:global(.uui-disabled):hover, .item-root:global(.uui-disabled):focus {
  background-color: transparent;
  cursor: default;
}
.item-root:global(.uui-opened) {
  background-color: var(--uui-dropdown_menu-item-active);
  fill: var(--uui-text-primary);
}
.item-root > *:not(:last-child) {
  margin-inline: 0 7px;
}

.indent {
  padding-left: 24px;
}

.selected-mark {
  fill: var(--uui-dropdown-selected_mark);
}
.selected-mark > svg {
  width: 18px;
  height: 18px;
}