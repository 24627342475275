.root {
  --uui-calendar-selected_range-hover: var(--uui-primary-60);
  --uui-calendar-selected_range: var(--uui-primary-50);
  --uui-calendar-weekday-caption: var(--uui-text-tertiary);
  --uui-calendar-holiday-caption: var(--uui-text-tertiary);
  --uui-calendar-day-text: var(--uui-control-text);
  --uui-calendar-day-text-disabled: var(--uui-text-disabled);
  --uui-calendar-selected_day-text: var(--uui-neutral-0);
  --uui-calendar-selected_day-hover: var(--uui-control-bg);
  --uui-calendar-font: var(--uui-font);
  --uui-calendar-font-weight: 400;
  --uui-calendar-weekday-font: var(--uui-font);
  --uui-calendar-weekday-font-weight: 400;
  --uui-calendar-selected_day-font: var(--uui-font);
  --uui-calendar-selected_day-font-weight: 600;
  --uui-calendar-day-size: var(--uui-size);
  --uui-calendar-day-wrapper-height: calc(var(--uui-size) - var(--uui-grid-unit));
  --uui-calendar-day-border-width: var(--uui-border-width);
  --uui-calendar-day-border-radius: 50%;
  --uui-calendar-day-font-size: var(--uui-font-size);
  --uui-calendar-day-line-height: calc(var(--uui-calendar-day-size) - var(--uui-calendar-day-border-width) * 2);
  --uui-calendar-weekday-font-size: 12px;
  --uui-calendar-weekday-line-height: 24px;
  --uui-calendar-dot-size: 4px;
}
.root :global(.uui-calendar-content) {
  font-family: var(--uui-calendar-font);
  font-weight: var(--uui-calendar-font-weight, normal);
}
.root :global(.uui-calendar-weekday) {
  font-family: var(--uui-calendar-weekday-font);
  font-weight: var(--uui-calendar-weekday-font-weight, normal);
  color: var(--uui-calendar-weekday-caption);
  width: var(--uui-calendar-day-size);
  font-size: var(--uui-calendar-weekday-font-size);
  line-height: var(--uui-calendar-weekday-line-height);
  text-align: center;
}
.root :global(.uui-calendar-day-cell) {
  color: var(--uui-calendar-day-text);
  text-align: center;
  padding: 0;
  height: var(--uui-calendar-day-size);
  width: var(--uui-calendar-day-size);
  display: flex;
  position: relative;
}
.root :global(.uui-calendar-day-cell):focus {
  outline: none;
}
.root :global(.uui-calendar-day-wrapper) {
  height: var(--uui-calendar-day-wrapper-height);
  width: var(--uui-calendar-day-size);
  font-size: var(--uui-calendar-day-font-size);
  line-height: var(--uui-calendar-day-line-height);
  margin: auto;
  cursor: default;
}
.root :global(.uui-calendar-day) {
  border-radius: var(--uui-calendar-day-border-radius);
  height: var(--uui-calendar-day-size);
  width: var(--uui-calendar-day-size);
  position: absolute;
  left: 0;
  top: 0;
  font-size: var(--uui-calendar-day-font-size);
  line-height: var(--uui-calendar-day-line-height);
  border: var(--uui-calendar-day-border-width) solid transparent;
  box-sizing: border-box;
}
.root :global(.uui-calendar-clickable-day):hover:not(:global(.uui-calendar-filtered-day)), .root :global(.uui-calendar-clickable-day):focus:not(:global(.uui-calendar-filtered-day)) {
  outline: none;
  cursor: pointer;
}
.root :global(.uui-calendar-clickable-day):hover:not(:global(.uui-calendar-filtered-day)) :global(.uui-calendar-day), .root :global(.uui-calendar-clickable-day):focus:not(:global(.uui-calendar-filtered-day)) :global(.uui-calendar-day) {
  border: var(--uui-calendar-day-border-width) solid var(--uui-calendar-selected_range);
  background-color: var(--uui-calendar-selected_day-hover);
  z-index: 1;
}
.root :global(.uui-calendar-clickable-day):hover:not(:global(.uui-calendar-filtered-day)):global(.uui-calendar-selected-day) :global(.uui-calendar-day), .root :global(.uui-calendar-clickable-day):focus:not(:global(.uui-calendar-filtered-day)):global(.uui-calendar-selected-day) :global(.uui-calendar-day) {
  border: var(--uui-calendar-day-border-width) solid var(--uui-calendar-selected_range-hover);
  background-color: var(--uui-calendar-selected_range-hover);
}
.root :global(.uui-calendar-current-day):global(.uui-calendar-day-wrapper) {
  color: var(--uui-calendar-selected_range);
  font-family: var(--uui-calendar-selected_day-font);
  font-weight: var(--uui-calendar-selected_day-font-weight, normal);
}
.root :global(.uui-calendar-current-day):global(.uui-calendar-day-wrapper) :global(.uui-calendar-day)::after {
  position: absolute;
  content: "";
  height: var(--uui-calendar-dot-size);
  width: var(--uui-calendar-dot-size);
  border-radius: var(--uui-calendar-day-border-radius);
  background-color: var(--uui-calendar-selected_range);
  bottom: calc(var(--uui-grid-unit) / 2);
  left: calc(50% - var(--uui-calendar-dot-size) / 2);
}
.root :global(.uui-calendar-selected-day):global(.uui-calendar-day-wrapper) :global(.uui-calendar-day) {
  background-color: var(--uui-calendar-selected_range);
  color: var(--uui-calendar-selected_day-text);
}
.root :global(.uui-calendar-selected-day):global(.uui-calendar-day-wrapper) :global(.uui-calendar-day)::after {
  background-color: var(--uui-calendar-selected_day-text);
}
.root :global(.uui-calendar-selected-day):global(.uui-calendar-clickable-day):hover {
  cursor: pointer;
}
.root :global(.uui-calendar-day-holiday) {
  color: var(--uui-calendar-holiday-caption);
}
.root :global(.uui-calendar-filtered-day):global(.uui-calendar-day-wrapper) {
  color: var(--uui-calendar-day-text-disabled);
}
.root :global(.uui-calendar-filtered-day):global(.uui-calendar-day-wrapper):hover {
  cursor: default;
  background-color: transparent;
}