:global(.uui-picker_input-body) {
  --uui-picker_input-selected_mark: var(--uui-info-50);
  --uui-data_picker_body-no-data-vertical-padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-self: stretch;
  width: 100%;
}
:global(.uui-picker_input-body) :global(.-scrolled-bottom) {
  border-bottom: 1px solid var(--uui-divider);
}
:global(.uui-picker_input-body).modal :global(.-scrolled-top) {
  border-top: 1px solid var(--uui-divider);
}
:global(.uui-picker_input-body).modal :global(.-scrolled-bottom) {
  border-bottom: 1px solid var(--uui-divider);
}

.search-wrapper ~ :global(.uui-picker_input-body):not(.modal) :global(.-scrolled-top) {
  border-top: 1px solid var(--uui-divider);
}

.modal {
  border: 0;
}

.search-wrapper {
  --uui-data_picker_body-search-padding: 9px;
  padding: var(--uui-data_picker_body-search-padding);
}

.no-data {
  padding-top: var(--uui-data_picker_body-no-data-vertical-padding);
  padding-bottom: var(--uui-data_picker_body-no-data-vertical-padding);
}

.type-search-to-load-size-24 {
  padding-top: 20px;
  padding-bottom: 20px;
}