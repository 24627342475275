.root {
  border-bottom: 0 none;
  border-inline-start: var(--uui-tab-line-width) solid transparent;
  text-align: start;
  column-gap: 0;
}
.root :global(.uui-caption):first-child {
  padding-inline-start: 0;
}
.root :global(.uui-caption):last-child {
  padding-inline-end: 0;
}
.root :global(.uui-count_indicator) {
  margin-inline-start: 3px;
  margin-inline-end: var(--uui-horizontal-gap);
}
.root :global(.uui-count_indicator) ~ :global(.uui-caption) {
  padding-inline-end: 0;
}