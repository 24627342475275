@import '~@epam/promo/assets/styles/colors';

.default {
  fill: $gray60;
}

.doc {
  fill: #2B579A;
}

.xls {
  fill: #217346;
}

.pdf {
  fill: #FA0F01;
}

.movie {
  fill: #5214CC;
}

.img {
  fill: #E67E17;
}
