.root {
  --uui-btn-bg: var(--uui-color-50);
  --uui-btn-bg-hover: var(--uui-color-60);
  --uui-btn-bg-active: var(--uui-color-70);
  --uui-btn-bg-disabled: var(--uui-neutral-30);
  --uui-btn-border: var(--uui-color-50);
  --uui-btn-border-hover: var(--uui-color-60);
  --uui-btn-border-active: var(--uui-color-70);
  --uui-btn-border-disabled: var(--uui-neutral-30);
  --uui-btn-caption: var(--uui-color-contrast);
  --uui-btn-caption-disabled: var(--uui-text-disabled);
  --uui-btn-font: var(--uui-font);
  --uui-btn-font-weight: var(--uui-font-weight, 600);
  --uui-btn-border-radius: var(--uui-border-radius);
  --uui-btn-min-height: var(--uui-size);
  --uui-btn-min-width: var(--uui-size);
  --uui-btn-border-width: var(--uui-border-width);
  --uui-btn-padding: var(--uui-horizontal-padding);
  --uui-btn-caption-padding: calc((var(--uui-btn-min-height) - var(--uui-btn-line-height) - (var(--uui-btn-border-width) * 2)) / 2) var(--uui-horizontal-gap);
  --uui-btn-icon-height: var(--uui-icon-size);
  --uui-btn-line-height: var(--uui-line-height);
  --uui-btn-font-size: var(--uui-font-size);
  min-width: var(--uui-btn-min-width);
  padding-inline-start: calc(var(--uui-btn-padding) - var(--uui-btn-border-width));
  padding-inline-end: calc(var(--uui-btn-padding) - var(--uui-btn-border-width));
  background-color: var(--uui-btn-bg);
  color: var(--uui-btn-caption);
  fill: var(--uui-btn-caption);
  border-color: var(--uui-btn-border);
}
.root:global(.-clickable):hover {
  background-color: var(--uui-btn-bg-hover);
  border-color: var(--uui-btn-border-hover);
}
.root:global(.-clickable):active {
  background-color: var(--uui-btn-bg-active);
  border-color: var(--uui-btn-border-active);
}
.root:global(.uui-button-box) {
  border-style: solid;
  justify-content: center;
  border-radius: var(--uui-btn-border-radius);
  min-height: var(--uui-btn-min-height);
  border-width: var(--uui-btn-border-width);
}
.root:focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: var(--uui-focus-outline-offset);
}
.root :global(.uui-caption) {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  text-align: center;
  flex: 0 1 auto;
  font-family: var(--uui-btn-font);
  font-weight: var(--uui-btn-font-weight, normal);
  user-select: none;
  padding: var(--uui-btn-caption-padding);
  line-height: var(--uui-btn-line-height);
  font-size: var(--uui-btn-font-size);
}
.root svg {
  height: var(--uui-btn-icon-height);
  width: inherit;
}
.root:is(:global(.-clickable), :hover, :active, :focus):global(.uui-disabled):global(.uui-fill-solid), .root:global(.uui-disabled):global(.uui-fill-solid) {
  background-color: var(--uui-btn-bg-disabled);
  color: var(--uui-btn-caption-disabled);
  fill: var(--uui-btn-caption-disabled);
  border-color: var(--uui-btn-border-disabled);
}
.root:is(:global(.-clickable), :hover, :active, :focus):global(.uui-disabled):global(.uui-fill-outline), .root:global(.uui-disabled):global(.uui-fill-outline) {
  background-color: var(--uui-surface-main);
  color: var(--uui-btn-caption-disabled);
  fill: var(--uui-btn-caption-disabled);
  border-color: var(--uui-btn-border-disabled);
}
.root:is(:global(.-clickable), :hover, :active, :focus):global(.uui-disabled):global(.uui-fill-none), .root:global(.uui-disabled):global(.uui-fill-none) {
  background-color: transparent;
  color: var(--uui-btn-caption-disabled);
  fill: var(--uui-btn-caption-disabled);
  border-color: var(--uui-btn-border-disabled);
}
.root:is(:global(.-clickable), :hover, :active, :focus):global(.uui-disabled):global(.uui-fill-ghost), .root:global(.uui-disabled):global(.uui-fill-ghost) {
  background-color: transparent;
  color: var(--uui-btn-caption-disabled);
  fill: var(--uui-btn-caption-disabled);
  border-color: transparent;
}
.root:global(.uui-fill-solid) {
  --uui-btn-bg: var(--uui-color-50);
  --uui-btn-bg-hover: var(--uui-color-60);
  --uui-btn-bg-active: var(--uui-color-70);
  --uui-btn-border: var(--uui-color-50);
  --uui-btn-border-hover: var(--uui-color-60);
  --uui-btn-border-active: var(--uui-color-70);
  --uui-btn-caption: var(--uui-color-contrast);
  --uui-btn-caption-disabled: var(--uui-text-disabled);
}
.root:global(.uui-fill-solid):global(.uui-color-white):active {
  background-color: var(--uui-neutral-30);
  border-color: var(--uui-neutral-30);
}
.root:global(.uui-fill-solid):global(.uui-color-white):global(.uui-disabled) {
  background-color: color-mix(in srgb, var(--uui-neutral-0) 10%, transparent);
  border-color: transparent;
  color: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
  fill: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
}
.root:global(.uui-fill-outline) {
  --uui-btn-bg: var(--uui-surface-main);
  --uui-btn-bg-hover: var(--uui-color-10);
  --uui-btn-bg-active: var(--uui-color-20);
  --uui-btn-bg-disabled: transparent;
  --uui-btn-border: var(--uui-color-50);
  --uui-btn-border-hover: var(--uui-color-50);
  --uui-btn-border-active: var(--uui-color-50);
  --uui-btn-caption: var(--uui-color-50);
  --uui-btn-caption-disabled: var(--uui-text-disabled);
}
.root:global(.uui-fill-outline):global(.uui-color-white) {
  --uui-btn-bg: var(--uui-color-5);
}
.root:global(.uui-fill-outline):global(.uui-color-white):global(.uui-disabled) {
  background-color: color-mix(in srgb, var(--uui-neutral-0) 10%, transparent);
  border-color: color-mix(in srgb, var(--uui-neutral-0) 30%, transparent);
  color: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
  fill: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
}
.root:global(.uui-fill-none) {
  --uui-btn-bg: transparent;
  --uui-btn-bg-hover: var(--uui-color-10);
  --uui-btn-bg-active: var(--uui-color-20);
  --uui-btn-bg-disabled: transparent;
  --uui-btn-border: var(--uui-color-50);
  --uui-btn-border-hover: var(--uui-color-50);
  --uui-btn-border-active: var(--uui-color-50);
  --uui-btn-caption: var(--uui-color-50);
  --uui-btn-caption-disabled: var(--uui-text-disabled);
}
.root:global(.uui-fill-none):global(.uui-color-white):global(.uui-disabled) {
  background-color: color-mix(in srgb, var(--uui-neutral-0) 0%, transparent);
  border-color: color-mix(in srgb, var(--uui-neutral-0) 30%, transparent);
  color: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
  fill: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
}
.root:global(.uui-fill-ghost) {
  --uui-btn-bg: transparent;
  --uui-btn-bg-hover: var(--uui-color-10);
  --uui-btn-bg-active: var(--uui-color-20);
  --uui-btn-bg-disabled: transparent;
  --uui-btn-border: transparent;
  --uui-btn-border-hover: var(--uui-color-10);
  --uui-btn-border-active: var(--uui-color-20);
  --uui-btn-border-disabled: transparent;
  --uui-btn-caption: var(--uui-color-50);
  --uui-btn-caption-disabled: var(--uui-text-disabled);
}
.root:global(.uui-fill-ghost):global(.uui-color-white) {
  --uui-btn-border-hover: transparent;
  --uui-btn-border-active: transparent;
}
.root:global(.uui-fill-ghost):global(.uui-color-white):global(.uui-disabled) {
  background-color: color-mix(in srgb, var(--uui-neutral-0) 0%, transparent);
  border-color: transparent;
  color: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
  fill: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
}