@import '~@epam/promo/assets/styles/index';

html,
body,
:global(#root) {
    height: 100%;
    overflow: hidden;
    margin: 0;
    display: flex;
    flex-direction: column;
}

* {
    box-sizing: border-box;
}

body

.container {
    overflow-y: hidden;
    overflow-x: hidden;
    position: fixed;
    height: 100%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: #21232E;
    justify-content: space-between;
}
