.root {
  --uui-filters_toggler-bg: var(--uui-control-bg);
  --uui-filters_toggler-bg-hover: var(--uui-neutral-5);
  --uui-filters_toggler-border: var(--uui-control-border);
  --uui-filters_toggler-border-hover: var(--uui-control-border-hover);
  --uui-filters_toggler-icon: var(--uui-icon);
  --uui-filters_toggler-label: var(--uui-text-tertiary);
  --uui-filters_toggler-shadow: none;
  --uui-filters_toggler-text: var(--uui-text-primary);
  --uui-filters_toggler-border-radius: var(--uui-border-radius);
  --uui-filters_toggler-font: var(--uui-font);
  --uui-filters_toggler-font-weight: 400;
  --uui-filters_toggler-min-height: var(--uui-size);
  --uui-filters_toggler-border-width: var(--uui-border-width);
  --uui-filters_toggler-horizontal-padding: calc(var(--uui-horizontal-padding) - var(--uui-filters_toggler-border-width));
  --uui-filters_toggler-caption-horizontal-padding: var(--uui-horizontal-gap);
  --uui-filters_toggler-icon-size: var(--uui-icon-size);
}
.root:global(.uui-input-box) {
  box-sizing: border-box;
  min-width: 40px;
  min-height: var(--uui-filters_toggler-min-height);
  position: relative;
  display: flex;
  outline: none;
  border: var(--uui-filters_toggler-border-width) solid var(--uui-filters_toggler-border);
  border-radius: var(--uui-filters_toggler-border-radius);
  background-color: var(--uui-filters_toggler-bg);
  box-shadow: var(--uui-filters_toggler-shadow);
  padding-left: var(--uui-filters_toggler-horizontal-padding);
  padding-right: var(--uui-filters_toggler-horizontal-padding);
}
.root:global(.uui-input-box):global(.-clickable):hover {
  cursor: pointer;
}
.root:global(.uui-input-box):hover, .root:global(.uui-input-box):focus {
  border-color: var(--uui-filters_toggler-border-hover);
  background-color: var(--uui-filters_toggler-bg-hover);
}
.root:global(.uui-input-box):global(.uui-opened) {
  border-color: var(--uui-filters_toggler-border-hover);
  background-color: var(--uui-filters_toggler-bg-hover);
}
.root:global(.uui-input-box) :global(.uui-icon-dropdown) {
  fill: var(--uui-filters_toggler-icon);
}
.root:global(.uui-input-box) .title-wrapper {
  font-family: var(--uui-filters_toggler-font);
  font-weight: var(--uui-filters_toggler-font-weight, normal);
  flex: 1 1 auto;
  min-width: 0;
  outline: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  padding-left: 6px;
  padding-right: 6px;
}
.root:global(.uui-input-box) .title-wrapper .title {
  display: flex;
  text-overflow: initial;
  overflow: visible;
  padding-top: 0;
  padding-bottom: 0;
  color: var(--uui-filters_toggler-label);
}
.root:global(.uui-input-box) .title-wrapper .text-wrapper {
  display: flex;
  overflow: hidden;
  align-items: baseline;
  width: 100%;
  padding-left: 3px;
}
.root:global(.uui-input-box) .title-wrapper .selection {
  padding-top: 0;
  padding-bottom: 0;
  color: var(--uui-filters_toggler-text);
}
.root:global(.uui-input-box) .title-wrapper .selection div {
  display: inline-block;
  margin-right: 3px;
}
.root:global(.uui-input-box) .title-wrapper .selection div :nth-last-child {
  margin-right: 0;
}
.root:global(.uui-input-box) .title-wrapper .selection + span {
  color: var(--uui-filters_toggler-text);
}
.root:global(.uui-input-box) .title-wrapper .postfix {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 6px;
  overflow: visible;
  color: var(--uui-filters_toggler-text);
}
.root:global(.uui-input-box) .title-wrapper .selected {
  color: var(--uui-filters_toggler-text);
}
.root:global(.uui-input-box) :global(.uui-icon) svg {
  height: var(--uui-filters_toggler-icon-size);
  width: inherit;
}