.root {
  --uui-dt-row-min-height: var(--uui-size);
  min-height: var(--uui-dt-row-min-height);
}
.root:global(.uui-dt-row-border.uui-table-row) {
  --uui-dt-row-border-width: var(--uui-border-width);
}
.root:global(.-clickable) {
  cursor: pointer;
}
.root:global(.-clickable):hover {
  --uui-dt-row-bg: var(--uui-dt-row-bg-hover);
}
.root:global(.-draggable):hover {
  cursor: grab;
}
.root:global(.-draggable):active {
  cursor: grabbing;
}
.root:global(.uui-selected.uui-table-row) {
  --uui-dt-row-bg: var(--uui-dt-row-bg-selected);
}
.root:global(.uui-invalid.uui-table-row) {
  --uui-dt-row-bg: var(--uui-dt-row-bg-invalid);
}
.root:global(.uui-dragged-out) {
  opacity: 0.5;
}
.root:global(.uui-drag-ghost) {
  box-shadow: var(--uui-shadow-level-3);
}
.root:global(.uui-dt-row-border) {
  border-bottom-color: var(--uui-dt-border);
}

:global(.uui-dt-row-border) :global(.uui-table-fixed-section), :global(.uui-dt-row-border) :global(.uui-table-scrolling-section) {
  border-bottom-color: var(--uui-dt-border);
}