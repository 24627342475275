.root {
  --uui-notification_card-bg: var(--uui-surface-main);
  --uui-notification_card-border: var(--uui-color-50);
  --uui-notification_card-fill: var(--uui-color-50);
  --uui-notification_card-shadow: var(--uui-shadow-level-2);
  --uui-notification_card-border-radius: var(--uui-border-radius);
  --uui-notification_card-border-width: 0;
  --uui-notification_card-border-top-width: 6px;
  --uui-notification_card-icon-size: 24px;
  border-style: solid;
  border-color: var(--uui-notification_card-border, transparent);
  border-width: var(--uui-notification_card-border-width);
  border-top-width: var(--uui-notification_card-border-top-width);
  padding: 6px 18px;
  width: 360px;
  background: var(--uui-notification_card-bg, var(--uui-surface-main));
  display: flex;
  overflow: hidden;
  border-radius: var(--uui-notification_card-border-radius);
  box-shadow: var(--uui-notification_card-shadow);
}
@media (max-width: 720px) {
  .root {
    width: 100%;
  }
}
.root .icon-wrapper {
  padding: 6px 0;
}
.root .action-wrapper {
  display: flex;
  align-items: center;
}
.root .action-link:not(:last-child) {
  margin-right: 12px;
}
.root .close-icon {
  padding: 6px 0;
}
.root .close-icon :global(.uui-icon) svg {
  height: var(--uui-notification_card-icon-size);
  width: inherit;
}
.root .main-path {
  width: 100%;
  display: flex;
  align-self: baseline;
}
.root .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0 12px;
}
.root .close-wrapper {
  display: flex;
  align-items: flex-start;
}
.root .icon-wrapper :global(.uui-icon) {
  fill: var(--uui-notification_card-fill);
}

.notification-wrapper {
  padding: 6px 18px;
  width: 360px;
  background: var(--uui-surface-main);
  border-radius: 3px;
  display: flex;
  overflow: hidden;
  box-shadow: var(--uui-shadow-level-2);
}

.clear-button {
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 18px;
}

@media (max-width: 720px) {
  .notification-wrapper {
    width: 100%;
  }
}