.root {
  --uui-rdtp-date-bg-selected: var(--uui-info-10);
  --uui-rdtp-blocker-bg: color-mix(in srgb, var(--uui-surface-higher) 80%, transparent);
  --uui-rdtp-separator-width: 1px;
  --uui-rdtp-calendar-day-top: -3px;
  --uui-rdtp-gradient-size: 6px;
  --uui-rdtp-day-width: 40px;
  --uui-rdtp-selected-day-radius: 50%;
  --uui-rdtp-margin-right: 10px;
}
.root :global(.uui-calendar-days) > div:last-child :global(.uui-calendar-day-cell):last-child > :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper)) {
  position: relative;
}
.root :global(.uui-calendar-days) > div:last-child :global(.uui-calendar-day-cell):last-child > :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper)) :global(.uui-calendar-day) {
  top: var(--uui-rdtp-calendar-day-top);
}
.root :global(.uui-calendar-days) > div:last-child :global(.uui-calendar-day-cell):last-child > :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper))::after {
  background: linear-gradient(320deg, var(--uui-surface-main) 3px, transparent 0), linear-gradient(-135deg, var(--uui-surface-main) 3px, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-y;
  background-size: var(--uui-rdtp-gradient-size) var(--uui-rdtp-gradient-size);
  content: " ";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: var(--uui-rdtp-gradient-size);
  height: 100%;
}
.root :global(.uui-calendar-days) > div:first-child :global(.uui-calendar-day-cell):empty + :global(.uui-calendar-day-cell) :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper)),
.root :global(.uui-calendar-days) > div:first-child :global(.uui-calendar-day-cell):first-child :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper)) {
  position: relative;
}
.root :global(.uui-calendar-days) > div:first-child :global(.uui-calendar-day-cell):empty + :global(.uui-calendar-day-cell) :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper)) :global(.uui-calendar-day),
.root :global(.uui-calendar-days) > div:first-child :global(.uui-calendar-day-cell):first-child :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper)) :global(.uui-calendar-day) {
  top: var(--uui-rdtp-calendar-day-top);
}
.root :global(.uui-calendar-days) > div:first-child :global(.uui-calendar-day-cell):empty + :global(.uui-calendar-day-cell) :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper))::after,
.root :global(.uui-calendar-days) > div:first-child :global(.uui-calendar-day-cell):first-child :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper))::after {
  background: linear-gradient(45deg, var(--uui-surface-main) 3px, transparent 0), linear-gradient(135deg, var(--uui-surface-main) 3px, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-y;
  background-size: var(--uui-rdtp-gradient-size) var(--uui-rdtp-gradient-size);
  content: " ";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: var(--uui-rdtp-gradient-size);
  height: 100%;
}
.root :global(.uui-range-datepicker-in-range):global(.uui-calendar-day-wrapper) {
  background-color: var(--uui-rdtp-date-bg-selected);
  border-radius: 0;
  width: var(--uui-rdtp-day-width);
}
.root :global(.uui-range-datepicker-first-day-in-range-wrapper):global(.uui-calendar-selected-day) {
  border-top-left-radius: var(--uui-rdtp-selected-day-radius);
  border-bottom-left-radius: var(--uui-rdtp-selected-day-radius);
}
.root :global(.uui-range-datepicker-last-day-in-range-wrapper):global(.uui-calendar-selected-day) {
  border-top-right-radius: var(--uui-rdtp-selected-day-radius);
  border-bottom-right-radius: var(--uui-rdtp-selected-day-radius);
  margin-right: var(--uui-rdtp-margin-right);
}
.root :global(.uui-range-datepicker-separator) {
  display: flex;
  flex: 0 0 auto;
  border-left: var(--uui-rdtp-separator-width) solid var(--uui-control-border);
}

.container.day-selection .from-picker :global(.uui-datepicker-header-nav-icon-right) {
  visibility: hidden;
}
.container.day-selection .to-picker :global(.uui-datepicker-header-nav-icon-left) {
  visibility: hidden;
}
.container .bodes-wrapper {
  position: relative;
  transition: height 0.3s ease-in-out;
}
.container .blocker {
  background-color: var(--uui-rdtp-blocker-bg);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

:global(.uui-datepicker-container) {
  transition: height 0.3s ease-in-out;
}