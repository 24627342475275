.root {
  --uui-radio_input-bg: var(--uui-control-bg);
  --uui-radio_input-bg-disabled: var(--uui-control-bg-disabled);
  --uui-radio_input-border: var(--uui-control-border);
  --uui-radio_input-border-hover: var(--uui-color-60);
  --uui-radio_input-border-checked: var(--uui-color-50);
  --uui-radio_input-border-checked-disabled: var(--uui-color-20);
  --uui-radio_input-border-disabled: var(--uui-control-border-disabled);
  --uui-radio_input-label: var(--uui-text-primary);
  --uui-radio_input-label-disabled: var(--uui-text-disabled);
  --uui-radio_input-fill-hover: var(--uui-color-60);
  --uui-radio_input-fill-checked: var(--uui-color-50);
  --uui-radio_input-fill-checked-disabled: var(--uui-color-20);
  --uui-radio_input-error: var(--uui-error-50);
  --uui-radio_input-font: var(--uui-font);
  --uui-radio_input-font-weight: 400;
  --uui-radio_input-size: var(--uui-size);
  --uui-radio_input-bullet-size: var(--uui-size);
  --uui-radio_input-border-radius: var(--uui-border-radius);
  --uui-radio_input-border-width: var(--uui-border-width);
  --uui-radio_input-horizontal-gap: var(--uui-horizontal-gap);
  --uui-radio_input-line-height: var(--uui-line-height);
  --uui-radio_input-font-size: var(--uui-font-size);
  column-gap: var(--uui-radio_input-horizontal-gap);
}
.root :global(.uui-radioinput) {
  height: var(--uui-radio_input-size);
  width: var(--uui-radio_input-size);
  border-radius: calc(var(--uui-radio_input-size) / 2);
  background-color: var(--uui-radio_input-bg);
  border: var(--uui-radio_input-border-width) solid var(--uui-radio_input-border);
  position: relative;
  align-self: baseline;
  flex-shrink: 0;
  box-sizing: border-box;
}
.root :global(.uui-radioinput) :global(input[type="radio"]):focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: var(--uui-focus-outline-offset);
  width: inherit;
  height: inherit;
  position: absolute;
}
.root :global(.uui-radioinput):global(.uui-checked) {
  border-color: var(--uui-radio_input-border-checked);
}
.root :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon) {
  fill: var(--uui-radio_input-fill-checked);
  position: absolute;
  top: calc(0px - var(--uui-radio_input-border-width));
  left: calc(0px - var(--uui-radio_input-border-width));
}
.root :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon) svg {
  height: var(--uui-radio_input-bullet-size);
  width: var(--uui-radio_input-bullet-size);
}
.root :global(.uui-input-label) {
  color: var(--uui-radio_input-label);
  font-family: var(--uui-radio_input-font);
  font-weight: var(--uui-radio_input-font-weight);
  font-size: var(--uui-radio_input-font-size);
  line-height: var(--uui-radio_input-line-height);
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.root:hover :global(.uui-radioinput), .root:focus-within :global(.uui-radioinput) {
  border-color: var(--uui-radio_input-border-hover);
}
.root:hover :global(.uui-radioinput):global(.uui-checked), .root:focus-within :global(.uui-radioinput):global(.uui-checked) {
  border-color: var(--uui-radio_input-border-hover);
}
.root:hover :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon), .root:focus-within :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon) {
  fill: var(--uui-radio_input-fill-hover);
}
.root:hover:global(.uui-disabled) :global(.uui-radioinput), .root:hover:global(.uui-readonly) :global(.uui-radioinput), .root:focus-within:global(.uui-disabled) :global(.uui-radioinput), .root:focus-within:global(.uui-readonly) :global(.uui-radioinput) {
  background-color: var(--uui-radio_input-bg-disabled);
  border-color: var(--uui-radio_input-border-disabled);
}
.root:hover:global(.uui-disabled) :global(.uui-radioinput) :global(.uui-icon), .root:hover:global(.uui-readonly) :global(.uui-radioinput) :global(.uui-icon), .root:focus-within:global(.uui-disabled) :global(.uui-radioinput) :global(.uui-icon), .root:focus-within:global(.uui-readonly) :global(.uui-radioinput) :global(.uui-icon) {
  fill: var(--uui-radio_input-fill-checked-disabled);
}
.root:global(.uui-invalid) :global(.uui-icon) {
  fill: var(--uui-radio_input-error);
}
.root:global(.uui-invalid) :global(.uui-input-label) {
  color: var(--uui-radio_input-error);
}
.root:global(.uui-invalid) :global(.uui-radioinput) {
  border-color: var(--uui-radio_input-error);
}
.root:global(.uui-invalid) :global(.uui-checked):global(.uui-radioinput) :global(.uui-icon) {
  fill: var(--uui-radio_input-error);
}
.root:global(.uui-invalid):hover :global(.uui-radioinput), .root:global(.uui-invalid):focus-within :global(.uui-radioinput) {
  border-color: var(--uui-radio_input-error);
}
.root:global(.uui-invalid):hover :global(.uui-icon), .root:global(.uui-invalid):focus-within :global(.uui-icon) {
  fill: var(--uui-radio_input-error);
}
.root:global(.uui-disabled), .root:global(.uui-readonly) {
  cursor: default;
}
.root:global(.uui-disabled) :global(.uui-radioinput), .root:global(.uui-readonly) :global(.uui-radioinput) {
  cursor: default;
  background-color: var(--uui-radio_input-bg-disabled);
  border-color: var(--uui-radio_input-border-disabled);
}
.root:global(.uui-disabled) :global(.uui-radioinput) :global(.uui-icon), .root:global(.uui-readonly) :global(.uui-radioinput) :global(.uui-icon) {
  fill: var(--uui-radio_input-fill-checked-disabled);
}
.root:global(.uui-disabled) :global(.uui-radioinput):global(.uui-checked), .root:global(.uui-readonly) :global(.uui-radioinput):global(.uui-checked) {
  border-color: var(--uui-radio_input-border-checked-disabled);
}
.root:global(.uui-disabled) :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon), .root:global(.uui-readonly) :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon) {
  fill: var(--uui-radio_input-fill-checked-disabled);
}
.root:global(.uui-disabled):global(.uui-invalid) :global(.uui-radioinput), .root:global(.uui-readonly):global(.uui-invalid) :global(.uui-radioinput) {
  cursor: default;
  background-color: var(--uui-radio_input-bg-disabled);
  border-color: var(--uui-radio_input-border-disabled);
}
.root:global(.uui-disabled):global(.uui-invalid) :global(.uui-radioinput) :global(.uui-icon), .root:global(.uui-readonly):global(.uui-invalid) :global(.uui-radioinput) :global(.uui-icon) {
  fill: var(--uui-radio_input-fill-checked-disabled);
}
.root:global(.uui-readonly) :global(.uui-input-label) {
  color: var(--uui-radio_input-label);
}
.root:global(.uui-disabled) :global(.uui-input-label) {
  color: var(--uui-radio_input-label-disabled);
}