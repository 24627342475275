.root {
  --uui-alert-text: var(--uui-text-primary);
  --uui-alert-bg: var(--uui-color-10);
  --uui-alert-border: var(--uui-color-50);
  --uui-alert-icon: var(--uui-color-50);
  --uui-alert-min-height: var(--uui-size);
  --uui-alert-min-width: 170px;
  --uui-alert-border-radius: var(--uui-border-radius);
  --uui-alert-border-width: 0px;
  --uui-alert-indicator-border-width: 6px;
  --uui-alert-gap-h: 12px;
  --uui-alert-gap-v: 0px;
  --uui-alert-padding-h: 18px;
  --uui-alert-padding-v: 9px;
  --uui-alert-caption-gap-h: 12px;
  --uui-alert-icon-size: var(--uui-icon-size);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: var(--uui-alert-border-radius);
  background-color: var(--uui-alert-bg);
  border-width: var(--uui-alert-border-width);
  border-inline-start: var(--uui-alert-indicator-border-width) solid var(--uui-alert-border);
  padding-inline-start: calc(var(--uui-alert-padding-h) - var(--uui-alert-indicator-border-width));
  padding-inline-end: var(--uui-alert-padding-h);
  padding-top: var(--uui-alert-padding-v);
  padding-bottom: var(--uui-alert-padding-v);
  min-height: var(--uui-alert-min-height);
  min-width: var(--uui-alert-min-width);
}
.root .main-path {
  width: 100%;
  display: flex;
  column-gap: var(--uui-alert-gap-h);
}
.root .content-wrapper {
  display: flex;
  column-gap: var(--uui-alert-caption-gap-h);
  width: 100%;
}
.root .content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  column-gap: var(--uui-alert-gap-h);
  row-gap: var(--uui-alert-gap-v);
  width: 100%;
}
.root .action-wrapper {
  display: flex;
  align-items: center;
  column-gap: var(--uui-alert-gap-h);
}
.root :global(.uui-text) {
  color: var(--uui-alert-text);
}
.root svg {
  height: var(--uui-alert-icon-size);
  width: inherit;
}
.root .icon-wrapper {
  height: 100%;
}
.root .icon-wrapper :global(.uui-icon) {
  fill: var(--uui-alert-icon);
}
.root .icon {
  min-height: calc(var(--uui-alert-min-height) - 2 * var(--uui-alert-padding-v) - 2 * var(--uui-alert-border-width));
}
.root .close-icon {
  min-height: calc(var(--uui-alert-min-height) - 2 * var(--uui-alert-padding-v) - 2 * var(--uui-alert-border-width));
  min-width: fit-content;
  align-self: flex-start;
}