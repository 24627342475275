.root {
  --uui-tag-bg: var(--uui-color-50);
  --uui-tag-bg-hover: var(--uui-color-60);
  --uui-tag-border: var(--uui-color-50);
  --uui-tag-border-hover: var(--uui-color-60);
  --uui-tag-caption: var(--uui-color-contrast);
  --uui-tag-fill: var(--uui-color-contrast);
  --uui-tag-font: var(--uui-font);
  --uui-tag-font-weight: 400;
  --uui-tag-min-height: var(--uui-size);
  --uui-tag-min-width: var(--uui-size);
  --uui-tag-border-width: var(--uui-border-width);
  --uui-tag-border-radius: var(--uui-border-radius);
  --uui-tag-horizontal-padding: var(--uui-horizontal-padding);
  --uui-tag-vertical-padding: var(--uui-vertical-padding);
  --uui-tag-horizontal-gap: var(--uui-horizontal-gap);
  --uui-tag-caption-horizontal-padding: var(--uui-horizontal-gap);
  --uui-tag-caption-vertical-padding: calc((var(--uui-tag-min-height) - var(--uui-tag-line-height) - var(--uui-tag-border-width) * 2) / 2);
  --uui-tag-count_indicator-margin-end: var(--uui-horizontal-gap);
  --uui-tag-icon-height: var(--uui-icon-size);
  --uui-tag-line-height: var(--uui-line-height);
  --uui-tag-font-size: var(--uui-font-size);
  text-decoration: none;
  outline: none;
  border: 0;
  background: none;
  box-sizing: border-box;
  appearance: none;
  align-items: unset;
  padding: unset;
  margin: unset;
  justify-content: unset;
  overflow: hidden;
  display: flex;
  position: relative;
}
.root:hover {
  text-decoration: none;
}
.root:global(.-clickable) {
  cursor: pointer;
}
.root:global(.uui-disabled) {
  cursor: default;
}
.root {
  min-height: var(--uui-tag-min-height);
  min-width: var(--uui-tag-min-width);
  column-gap: var(--uui-tag-horizontal-gap);
  padding-inline-start: calc(var(--uui-tag-horizontal-padding) - var(--uui-tag-border-width));
  padding-inline-end: calc(var(--uui-tag-horizontal-padding) - var(--uui-tag-border-width));
}
.root :global(.uui-caption) {
  font-size: var(--uui-tag-font-size);
  font-family: var(--uui-tag-font);
  font-weight: var(--uui-tag-font-weight);
  color: var(--uui-tag-caption);
  line-height: var(--uui-tag-line-height);
  padding-top: var(--uui-tag-caption-vertical-padding);
  padding-bottom: var(--uui-tag-caption-vertical-padding);
  padding-inline-start: var(--uui-tag-caption-horizontal-padding);
  padding-inline-end: var(--uui-tag-caption-horizontal-padding);
}
.root:global(.uui-button-box) :global(.uui-icon) svg {
  height: var(--uui-tag-icon-height);
  width: inherit;
}
.root:global(.uui-button-box) {
  border-radius: var(--uui-tag-border-radius);
  background-color: var(--uui-tag-bg);
  fill: var(--uui-tag-fill);
  border: var(--uui-tag-border-width) solid var(--uui-tag-border);
}
.root:global(.uui-button-box):global(.-clickable):hover, .root:global(.uui-button-box):global(.-clickable):active {
  background-color: var(--uui-tag-bg-hover);
  border-color: var(--uui-tag-border-hover);
  cursor: pointer;
}
.root:global(.uui-button-box):global(.-clickable):focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: var(--uui-focus-outline-offset);
}
.root:global(.uui-fill-solid) :global(.uui-count_indicator) {
  --uui-count_indicator-bg: color-mix(in srgb, var(--uui-neutral-0) 10%, transparent);
  --uui-count_indicator-border: var(--uui-neutral-30);
}
.root:global(.uui-fill-outline) {
  --uui-tag-bg: var(--uui-color-5);
  --uui-tag-bg-hover: var(--uui-color-10);
  --uui-tag-border: var(--uui-color-20);
  --uui-tag-border-hover: var(--uui-color-20);
  --uui-tag-caption: var(--uui-neutral-70);
  --uui-tag-fill: var(--uui-neutral-70);
}
.root:global(.uui-fill-outline) :global(.uui-count_indicator) {
  --uui-count_indicator-bg: var(--uui-neutral-0);
  --uui-count_indicator-border: var(--uui-neutral-40);
  --uui-count_indicator-caption: var(--uui-neutral-70);
}