.root {
  --uui-modals-shadow: var(--uui-shadow-level-3);
  --uui-modals-bg: var(--uui-surface-main);
  --uui-modals-title: var(--uui-text-primary);
  --uui-modals-title-font: var(--uui-font);
  --uui-modals-title-font-weight: 600;
  --uui-modals-title-font-size: 18px;
  --uui-modals-title-line-height: 24px;
  --uui-modals-border-radius: calc(var(--uui-border-radius) * 2);
}

.modal-blocker {
  animation-duration: 0.3s;
  animation-name: animateModalBlocker;
  opacity: 1;
  transition: all 0.3s ease-out;
  background-color: var(--uui-overlay);
}
@keyframes animateModalBlocker {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal:global(.uui-modal-window) {
  background-color: var(--uui-modals-bg);
  overflow: auto;
  border-radius: var(--uui-modals-border-radius);
  max-width: 100vw;
  box-shadow: var(--uui-modals-shadow);
}
@media (max-width: 720px) {
  .modal:global(.uui-modal-window) {
    position: fixed;
    inset-inline-start: 0;
    inset-block-end: 0;
    min-width: 100vw;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.modal:global(.uui-modal-window) :global(.uui-shadow-top)::before {
  background: var(--uui-divider);
  height: 1px;
}
.modal:global(.uui-modal-window) :global(.uui-shadow-bottom)::after {
  background: var(--uui-divider);
  height: 1px;
}

.modal-footer {
  border-bottom-left-radius: var(--uui-modals-border-radius);
  border-bottom-right-radius: var(--uui-modals-border-radius);
}
@media (max-width: 720px) {
  .modal-footer {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.modal-header {
  border-top-left-radius: var(--uui-modals-border-radius);
  border-top-right-radius: var(--uui-modals-border-radius);
}
.modal-header :global(.uui-modal-title) {
  font-family: var(--uui-modals-title-font);
  font-size: var(--uui-modals-title-font-size);
  line-height: var(--uui-modals-title-line-height);
  font-weight: var(--uui-modals-title-font-weight);
  color: var(--uui-modals-title);
  overflow: hidden;
  text-overflow: ellipsis;
}