.done {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--uui-divider);
}
.done:global(.uui-button-box) {
  background-color: var(--uui-surface-main);
  border-width: 1px !important;
}

@media screen and (max-width: 720px) {
  .container {
    height: 100dvh;
    width: 100dvw;
  }
}